import {Box, Select, Text} from "grommet";
import {Divider} from "@mui/material";
import React from "react";
import {DataGrid} from "@mui/x-data-grid";
import {useLazyQuery, useMutation} from "@apollo/client";
import {DELETE_MACHINE_PRODUCT, GET_PRODUCTS, QUERY_MACHINE_AREAS, QUERY_MACHINE_PRODUCTS} from "./ProductGQL.jsx";
import {tenant, tenantType} from "../tenant.js";
import Button from '@mui/material/Button';

function WareHouseMachine() {

    const [macSel,setMacSel] = React.useState(null);
    const [macList,setMacList] = React.useState([]);
    const [machineProductsList,setMachineProductsList] = React.useState([]);
    const [ queryMachineAreas,{loading}
    ] = useLazyQuery(QUERY_MACHINE_AREAS, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            const machinesList=data[`${tenant}_machines_machinearea`];
            if(machinesList){
                setMacList(machinesList.map((el)=>el[`machines_machine${tenantType}`]).sort((a, b) => a.name.localeCompare(b.name)))
            }
        },
        notifyOnNetworkStatusChange: true, // did the work
    });
    const [ queryMachineProducts,{loading:loadingMachineProducts}
    ] = useLazyQuery(QUERY_MACHINE_PRODUCTS, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            const machineProductsListApp=data[`${tenant}_products_machineproduct`];
            if(machineProductsListApp){
                setMachineProductsList(machineProductsListApp)
            }
        },
        notifyOnNetworkStatusChange: true, // did the work
    });
    const [deleteMachineProduct] = useMutation(DELETE_MACHINE_PRODUCT)

    function deleteMachineProductMovement(id){
        let result = window.confirm("Sei sicuro di eliminare il prodotto dal macchinario?")
        if(result){
            deleteMachineProduct({variables:{id:id}})
                .then((value)=>{
                    queryMachineProducts({variables:{machineId:macSel?.id}});
                    alert("Elemento cancellato correttamente");
                })
                .catch((error)=>alert("Errore durante l'operazione"))
        }
    }

    React.useEffect(() => {
        queryMachineAreas({variables:{areas:JSON.parse(sessionStorage.getItem("Cantiere"))}});
    },[])

    React.useEffect(()=>{
        queryMachineProducts({variables:{machineId:macSel?.id}});
    },[macSel])

    const columnsMachineProducts = [
        { field: `products_product.name`, headerName: 'Nome prodotto', width: 300 ,
            renderCell: (cellValues) => {
               console.log(cellValues.row)
                return(
                    <Box width="150px">
                        <Text size={"small"}>{cellValues.row[`products_product`]["name"]}</Text>
                    </Box>
                )
            }
        },
        { field: `products_product.code`, headerName: 'Codice prodotto', width: 300 ,
            renderCell: (cellValues) => {
                return(
                    <Box width="150px">
                        <Text size={"small"}>{cellValues.row[`products_product`]["code"]}</Text>
                    </Box>
                )
            }
        },
        { field: `products_product.description`, headerName: 'Descrizione', width: 300 ,
            renderCell: (cellValues) => {
                return(
                    <Box width="150px">
                        <Text size={"small"}>{cellValues.row[`products_product`]["description"]}</Text>
                    </Box>
                )
            }
        },
        { field: 'quantity', headerName: 'Quantità', width: 150 },
        { field: `users_user${tenantType}`, headerName: 'Utente', width: 300 ,
            renderCell: (cellValues) => {
                return(
                    <Box width="150px">
                        <Text size={"small"}>{cellValues.row[`users_user${tenantType}`]["name"]} {cellValues.row[`users_user${tenantType}`]["surname"]}</Text>
                    </Box>
                )
            }
        },
        { field: `id`, headerName: 'Utente', width: 300 ,
            renderCell: (cellValues) => {
                return(
                    <Button variant="contained" color="error" onClick={deleteMachineProductMovement}>
                        Elimina
                    </Button>
                )
            }
        },

    ]

    return(
        <Box margin="small">
            <Box overflow="hidden" aligns="center" pad="small" margin="10px">
                <Text color="#397aa3" size="large" weight="bold" alignSelf="start">MACCHINE-PRODOTTI</Text>
                <Divider/>
            </Box>
            <Box marginBottom={"30px"} direction="raw" margin={"small"} gap={"small"}>
                <Text color="#397aa3" margin="small" style={{fontWeight:'bold'}}>Seleziona macchinario: </Text>
                <Select
                    options={macList}
                    labelKey={"name"}
                    value={macSel}
                    onChange={({ option }) => setMacSel(option)}
                />
            </Box>
            <div style={{height: '70vh', width: '100%'}}>
                <DataGrid
                    rows={machineProductsList}
                    columns={columnsMachineProducts}
                    pageSize={100}
                    onPageChange={(page, details) => {
                        //setPageNumber(page);
                    }}
                />
            </div>
        </Box>
    );
}

export default WareHouseMachine;